exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-book-tsx": () => import("./../../../src/pages/book.tsx" /* webpackChunkName: "component---src-pages-book-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-about-tsx": () => import("./../../../src/pages/legal/about.tsx" /* webpackChunkName: "component---src-pages-legal-about-tsx" */),
  "component---src-pages-legal-licenses-tsx": () => import("./../../../src/pages/legal/licenses.tsx" /* webpackChunkName: "component---src-pages-legal-licenses-tsx" */),
  "component---src-pages-legal-notice-tsx": () => import("./../../../src/pages/legal/notice.tsx" /* webpackChunkName: "component---src-pages-legal-notice-tsx" */),
  "component---src-pages-nordlicht-anreise-tsx": () => import("./../../../src/pages/nordlicht/anreise.tsx" /* webpackChunkName: "component---src-pages-nordlicht-anreise-tsx" */),
  "component---src-pages-nordlicht-fotos-tsx": () => import("./../../../src/pages/nordlicht/fotos.tsx" /* webpackChunkName: "component---src-pages-nordlicht-fotos-tsx" */),
  "component---src-pages-nordlicht-preise-tsx": () => import("./../../../src/pages/nordlicht/preise.tsx" /* webpackChunkName: "component---src-pages-nordlicht-preise-tsx" */),
  "component---src-pages-nordlicht-preise-update-tsx": () => import("./../../../src/pages/nordlicht/preise/update.tsx" /* webpackChunkName: "component---src-pages-nordlicht-preise-update-tsx" */),
  "component---src-pages-nordlicht-wohnung-tsx": () => import("./../../../src/pages/nordlicht/wohnung.tsx" /* webpackChunkName: "component---src-pages-nordlicht-wohnung-tsx" */),
  "component---src-pages-suedwind-anreise-tsx": () => import("./../../../src/pages/suedwind/anreise.tsx" /* webpackChunkName: "component---src-pages-suedwind-anreise-tsx" */),
  "component---src-pages-suedwind-fotos-tsx": () => import("./../../../src/pages/suedwind/fotos.tsx" /* webpackChunkName: "component---src-pages-suedwind-fotos-tsx" */),
  "component---src-pages-suedwind-preise-tsx": () => import("./../../../src/pages/suedwind/preise.tsx" /* webpackChunkName: "component---src-pages-suedwind-preise-tsx" */),
  "component---src-pages-suedwind-preise-update-tsx": () => import("./../../../src/pages/suedwind/preise/update.tsx" /* webpackChunkName: "component---src-pages-suedwind-preise-update-tsx" */),
  "component---src-pages-suedwind-wohnung-tsx": () => import("./../../../src/pages/suedwind/wohnung.tsx" /* webpackChunkName: "component---src-pages-suedwind-wohnung-tsx" */)
}

